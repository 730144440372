import { adapterForWxMp } from './utilities'
import type { AuthOptions } from '@cloudbase/oauth'

declare const wx: any

class OauthClientStorageBase {
  public readonly localStorage
  constructor(config?: { localStorage: any /* StorageInterface */ }) {
    this.localStorage = config?.localStorage || (globalThis as any).localStorage
  }
  /**
   * Get Item
   * @param {string} key
   * @return {Promise<string | null>}
   */
  async getItem(key: string): Promise<string | null> {
    return this.localStorage.getItem(key)
  }

  /**
   * Remove Item.
   * @param {string} key
   * @return {Promise<void>}
   */
  async removeItem(key: string) {
    this.localStorage.removeItem(key)
  }

  /**
   * Set Item.
   * @param {string} key
   * @param {string} value
   * @return {Promise<void>}
   */
  async setItem(key: string, value: string): Promise<void> {
    this.localStorage.setItem(key, value)
  }

  getItemSync(key: string): string | null {
    return this.localStorage.getItem(key)
  }

  setItemSync(key: string, value: string) {
    this.localStorage.setItem(key, value)
  }

  removeItemSync(key: string) {
    this.localStorage.removeItem(key)
  }
}

/**
 * copy to git@git.woa.com:QBase/lcap/datasource.git/packages/cloud-sdk/common
 * 同步修改
 */
const URL_REG = /^[^:]+:\/\/[^/]+(\/[^?#]+)/

function generateOauthClientRequest(reqClass) {
  return async function (
    url: string,
    options?: {
      body?: any | null
      headers?: any | null
      method?: string
      [key: string]: any
    },
  ) {
    const requestId = options?.headers?.['x-request-id']

    return new Promise((resolve, reject) => {
      // Objects must be copied to prevent modification of data such as body.
      const copyOptions = Object.assign({}, options)
      if (copyOptions.body && typeof copyOptions.body !== 'string') {
        copyOptions.body = JSON.stringify(copyOptions.body)
      }

      const task = wx.request({
        url,
        data: copyOptions.body,
        timeout: reqClass._timeout,
        method: copyOptions.method || 'GET',
        header: copyOptions.headers,
        success(res) {
          if (res.code || res.data?.error_code) {
            res = {
              error: res.code || res.data.error,
              error_description: res.data.error_description || res.message || res.code || res.data.error_code,
              request_id: res.requestId,
              error_code: res.data?.error_code,
            }
          }

          if (!res.request_id) {
            res.request_id = res.request_id || requestId
          }

          if (res.error) {
            res.error_uri = getUrlPath(url)
            reject(res)
          }
          resolve(res.data || {})
        },
        fail(err) {
          reject({
            error: 'unreachable',
            error_description: (err as Error).message,
          })
        },
        complete(err) {
          if (!err || !err.errMsg) {
            return
          }
          if (!reqClass._timeout || reqClass._restrictedMethods.indexOf('request') === -1) {
            return
          }
          const { errMsg } = err
          if (errMsg === 'request:fail timeout') {
            console.warn(reqClass._timeoutMsg)
            try {
              task.abort()
            } catch (e) {}
          }
        },
      })
    })
  }
}

/**
 * Get url path.
 * @param {string} url
 * @return {string}
 */
function getUrlPath(url: string) {
  // return path if matched, or original url
  return URL_REG.test(url) ? RegExp.$1 : url
}

export const useAuthAdapter = (config: AuthOptions) => {
  if (!adapterForWxMp.isMatch() || (config.storage && config.baseRequest)) return config

  const authOptions: any = {}
  try {
    const { localStorage, reqClass: ReqClass } = adapterForWxMp.genAdapter()
    if (localStorage) {
      authOptions.storage = new OauthClientStorageBase({ localStorage })
    }
    if (ReqClass) {
      const reqClass = new ReqClass({
        timeout: 10000,
        restrictedMethods: ['get', 'post', 'upload', 'download', 'request'],
      })
      authOptions.request = generateOauthClientRequest(reqClass)
    }

    if (config.captchaOptions) {
      authOptions.baseRequest = authOptions.request
      authOptions.request = undefined
    }

    return { ...config, ...authOptions }
  } catch (e) {
    console.error('adapter generate fail:', e)
  }

  return config
}
