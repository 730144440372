import type { SDKRequestInterface } from '@cloudbase/adapter-interface'
import { Bot } from './bot'
import * as models from './models'
import * as types from './type'

const { MODELS } = models

class AI {
  public aiBaseUrl: string
  public aiBotBaseUrl: string
  public bot: Bot

  constructor(private req: SDKRequestInterface, public baseUrl: string) {
    this.aiBaseUrl = `${baseUrl}/ai`
    this.aiBotBaseUrl = `${baseUrl}/aibot`
    this.bot = new Bot(this.botRequest, this.aiBotBaseUrl)
  }

  createModel<T extends keyof typeof MODELS>(model: T) {
    const SimpleModelConstructor = MODELS[model]
    const simpleModel: types.SimpleChatModel = new SimpleModelConstructor(this.modelRequest, this.aiBaseUrl)
    const reactModel = new models.ReactModel(simpleModel)
    return reactModel
  }

  registerModel(name: string, model: types.ChatModelConstructor) {
    if (MODELS[name] != null) {
      console.warn(`AI model ${name} already exists!`)
      return
    }
    MODELS[name] = model
  }

  modelRequest: types.ModelReq = async ({ url, data, headers, stream }) => {
    const fetchHeaders = {
      'Content-Type': 'application/json',
    }
    // 带 Accept: text/event-stream  请求头显式指定是 SSE 绕过 60s 的限制
    stream && Object.assign(fetchHeaders, { Accept: 'text/event-stream' })

    return (
      await this.req.fetch({
        method: 'post',
        headers: { ...fetchHeaders, ...headers },
        body: JSON.stringify(data),
        url,
        stream,
      })
    ).data
  }

  botRequest: types.BotReq = async ({ method, url, data = {}, headers, stream }) => {
    if (method === 'get') {
      return (await this.req.fetch({ url: `${url}?${objectToParam(data)}`, method, headers, stream })).data
    }

    const fetchHeaders = {
      'Content-Type': 'application/json',
    }
    // 带 Accept: text/event-stream  请求头显式指定是 SSE 绕过 60s 的限制
    stream && Object.assign(fetchHeaders, { Accept: 'text/event-stream' })

    return (
      await this.req.fetch({
        url,
        body: JSON.stringify(data),
        headers: {
          ...fetchHeaders,
          ...headers,
        },
        stream,
        method,
      })
    ).data

    function objectToParam(obj: Object) {
      return Object.entries(obj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    }
  }

  registerFunctionTool(functionTool: types.FunctionTool) {
    if (models.toolMap.has(functionTool.name)) {
      console.warn(`AI function tool ${functionTool.name} already exists and will be overwritten!`)
    }
    models.toolMap.set(functionTool.name, functionTool.fn)
  }
}

export { AI }
