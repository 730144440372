export default class AbortController {
  listeners: Array<CallableFunction> = []
  signal = {
    aborted: false,
    addEventListener: (e: string, f: CallableFunction) => {
      if (e === 'abort') {
        this.listeners.push(f)
      }
    },
  }
  constructor() {}

  abort() {
    if (this.signal.aborted) return

    this.signal.aborted = true
    this.listeners.forEach(f => f())
  }
}
