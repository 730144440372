export abstract class SimpleChatModel {
  public abstract doGenerate(data: BaseChatModelInput): Promise<DoGenerateOutput>

  public abstract doStream(data: BaseChatModelInput): Promise<DoStreamOutput>
}

type RawResponse = { rawResponse?: any }

export type DoGenerateOutput = BaseDoGenerateOutput & RawResponse

export type DoStreamOutput = AsyncIterableReadableStream<BaseDoStreamOutputChunk & RawResponse>

export type ChatModelConstructor = typeof SimpleChatModel

export type AsyncIterableReadableStream<T> = ReadableStream<T> & {
  [Symbol.asyncIterator]: () => { next(): Promise<IteratorResult<T>> }
}

export interface IModelReqInput {
  url: string
  headers?: Record<string, string>
  data?: Object
  stream?: boolean
}

export type ModelReq = <T extends IModelReqInput>(
  props: T,
) => T['stream'] extends true ? Promise<ReadableStream<Uint8Array>> : Promise<Object>

export interface IBotReqInput {
  url: string
  method: string
  headers?: Record<string, string>
  data?: Object
  stream?: boolean
}

export type BotReq = <T extends IBotReqInput>(
  props: T,
) => T['stream'] extends true ? Promise<ReadableStream<Uint8Array>> : Promise<Object>

export type UserMessage = {
  role: 'user'
  content: string
}

export type SystemMessage = {
  role: 'system'
  content: string
}

export type AssistantMessage = PlainAssistantMessage | ToolCallAssistantMessage

export type PlainAssistantMessage = {
  role: 'assistant'
  content: string
}

export type ToolCallAssistantMessage = {
  role: 'assistant'
  tool_calls: Array<ToolCall>
  content?: string
}

export type ToolMessage = {
  role: 'tool'
  tool_call_id: string
  content: string
}

export type ChatModelMessage = UserMessage | SystemMessage | AssistantMessage | ToolMessage

export type FunctionTool = {
  name: string
  description: string
  fn: CallableFunction
  parameters: object
}

// #region 大模型标准输入类型
export interface BaseChatModelInput {
  model: string
  messages: Array<ChatModelMessage>
  temperature?: number
  top_p?: number
  tools?: Array<ModelTool>
  tool_choice?: 'none' | 'auto' | 'custom'
}

/**
 * 给 LLM 发请求时要传的工具
 */
export type ModelTool = {
  type: string
  function: ModelToolFunction
}

/**
 * 给 LLM 发请求时要传的工具函数
 */
export type ModelToolFunction = {
  name: string
  description: string
  /**
   * 描述函数参数的 JSON Schema
   */
  parameters: object
}
// #endregion

// #region 大模型标准响应类型
export type ToolCall = {
  id: string
  type: string
  function: { name: string; arguments: string }
}

type FinishReason = 'tool_calls' | (string & {})

export type Usage = {
  completion_tokens: number
  prompt_tokens: number
  total_tokens: number
}

export interface BaseDoGenerateOutput {
  choices?: Array<{
    finish_reason?: FinishReason
    message?: ChatModelMessage
  }>
  usage?: Usage
}

export interface BaseDoStreamOutputChunk {
  choices?: Array<{
    finish_reason?: FinishReason
    delta?: ChatModelMessage
  }>
  usage?: Usage
}
// #endregion
