import {
  createAsyncIterable,
  TransformStream,
  toPolyfillReadable,
  intoStandardStream,
  isToolCallAssistantMessage,
} from '../../utils'
import type { ZhiPuGenerateTextOutput, ZhiPuInputData, ZhiPuStreamTextOutput } from './type'
import type {
  ModelReq,
  BaseChatModelInput,
  SimpleChatModel,
  DoStreamOutput,
  BaseDoStreamOutputChunk,
  DoGenerateOutput,
} from '../../type'

function processInput(input: BaseChatModelInput): ZhiPuInputData {
  const { messages, model, temperature, tool_choice, tools, top_p } = input

  const processToolChoice = () => {
    if (tool_choice && tool_choice !== 'auto') {
      console.warn('`tool_choice` is not \'auto\'')
    }
    return tool_choice as any
  }

  return {
    ...input,
    messages,
    model,
    temperature,
    tool_choice: processToolChoice(),
    tools,
    top_p,
  }
}

export class ZhiPuSimpleModel implements SimpleChatModel {
  public subUrl = 'zhipu/api/paas/v4/chat/completions'
  constructor(private req: ModelReq, public baseUrl: string, subUrl?: string) {
    if (subUrl != null) {
      this.subUrl = subUrl
    }
  }

  private get url() {
    return `${this.baseUrl}/${this.subUrl}`
  }

  public async doGenerate(_data: BaseChatModelInput): Promise<DoGenerateOutput> {
    const data = processInput(_data)
    const res = (await this.req({
      url: this.url,
      data: {
        ...data,
        stream: false,
      },
      stream: false,
    })) as ZhiPuGenerateTextOutput
    return { ...res, rawResponse: res }
  }

  public async doStream(_data: BaseChatModelInput): Promise<DoStreamOutput> {
    const data = processInput(_data)
    let isToolCall: null | boolean = null
    const _stream = await this.req({
      url: this.url,
      data: {
        ...data,
        stream: true,
      },
      stream: true,
    })
    const stream = toPolyfillReadable(_stream) as typeof _stream

    const zhipuStream = intoStandardStream<ZhiPuStreamTextOutput>(stream)
    const streamWithRaw = zhipuStream.pipeThrough(new TransformStream<ZhiPuStreamTextOutput, BaseDoStreamOutputChunk & { rawResponse?: any }>({
      transform(chunk, controller) {
        const newChoices = chunk.choices.map((choice) => {
          const message = choice.delta
          if (isToolCall == null) isToolCall = isToolCallAssistantMessage(message)
          if (isToolCall) {
            return {
              ...choice,
              finish_reason: 'tool_calls' as const,
              delta: message,
            }
          }
          return choice
        })
        const newChunk = { ...chunk, choices: newChoices }
        controller.enqueue({ ...newChunk, rawResponse: chunk })
      },
    }),)

    return createAsyncIterable(streamWithRaw)
  }
}
