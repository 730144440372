import {
  createAsyncIterable,
  TransformStream,
  toPolyfillReadable,
  intoStandardStream,
  isToolCallAssistantMessage,
} from '../../utils'
import type {
  ModelReq,
  BaseChatModelInput,
  BaseDoStreamOutputChunk,
  DoStreamOutput,
  SimpleChatModel,
  DoGenerateOutput,
} from '../../type'
import { processInput, titleCaseToSnakeCase } from './util'
import { HunYuanGenerateTextOutput, HunYuanStreamTextOutput } from './type'

export class HunYuanSimpleModel implements SimpleChatModel {
  public subUrl = 'hunyuan'
  constructor(private req: ModelReq, public baseUrl: string, subUrl?: string) {
    if (subUrl != null) {
      this.subUrl = subUrl
    }
  }

  private get url() {
    return `${this.baseUrl}/${this.subUrl}`
  }

  public async doGenerate(data: BaseChatModelInput): Promise<DoGenerateOutput> {
    const res = (await this.req({
      url: this.url,
      headers: {
        'X-Tc-Action': 'ChatCompletions',
      },
      data: {
        ...processInput(data),
        stream: false,
      },
      stream: false,
    })) as { Response: any }
    const output = titleCaseToSnakeCase(res.Response) as HunYuanGenerateTextOutput
    return {
      ...output,
      rawResponse: res,
    }
  }

  public async doStream(data: BaseChatModelInput): Promise<DoStreamOutput> {
    let isToolCall: null | boolean = null

    const _stream = await this.req({
      url: this.url,
      headers: {
        'X-Tc-Action': 'ChatCompletions',
      },
      data: {
        ...data,
        stream: true,
      },
      stream: true,
    })
    const stream = toPolyfillReadable(_stream) as typeof _stream

    const hunyuanStream = intoStandardStream(stream)
    const streamWithRaw = hunyuanStream.pipeThrough(new TransformStream<unknown, BaseDoStreamOutputChunk & { rawResponse?: any }>({
      transform(_chunk, controller) {
        const chunk = titleCaseToSnakeCase(_chunk) as HunYuanStreamTextOutput
        const newChoices = chunk.choices.map((choice) => {
          const message = choice.delta
          if (isToolCall == null) isToolCall = isToolCallAssistantMessage(message)
          if (isToolCall) {
            return {
              ...choice,
              finish_reason: 'tool_calls',
              delta: message,
            }
          }
          return choice
        })
        const newChunk = { ...chunk, choices: newChoices }
        controller.enqueue({ ...newChunk, rawResponse: _chunk })
      },
    }),)

    return createAsyncIterable(streamWithRaw)
  }
}
