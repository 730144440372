import {
  createAsyncIterable,
  TransformStream,
  toPolyfillReadable,
  intoStandardStream,
  isToolCallAssistantMessage,
} from '../../utils'
import type {
  ModelReq,
  BaseChatModelInput,
  SimpleChatModel,
  DoStreamOutput,
  BaseDoStreamOutputChunk,
  DoGenerateOutput,
} from '../../type'
import { processInput } from '../HunYuan/util'
import { HunYuanGenerateTextOutput, HunYuanStreamTextOutput } from '../HunYuan/type'

export class HunYuanOpenSimpleModel implements SimpleChatModel {
  public subUrl = 'hunyuan-open/v1/chat/completions'
  constructor(private req: ModelReq, public baseUrl: string, subUrl?: string) {
    if (subUrl != null) {
      this.subUrl = subUrl
    }
  }

  private get url() {
    return `${this.baseUrl}/${this.subUrl}`
  }

  public async doGenerate(data: BaseChatModelInput): Promise<DoGenerateOutput> {
    const res = (await this.req({
      url: this.url,
      data: {
        ...processInput(data),
        stream: false,
      },
      stream: false,
    })) as HunYuanGenerateTextOutput
    return { ...res, rawResponse: res }
  }

  public async doStream(data: BaseChatModelInput): Promise<DoStreamOutput> {
    let isToolCall: null | boolean = null

    const _stream = await this.req({
      url: this.url,
      data: {
        ...processInput(data),
        stream: true,
      },
      stream: true,
    })
    const stream = toPolyfillReadable(_stream) as typeof _stream

    const hunyuanBetaStream = intoStandardStream<HunYuanStreamTextOutput>(stream)
    const streamWithRaw = hunyuanBetaStream.pipeThrough(new TransformStream<HunYuanStreamTextOutput, BaseDoStreamOutputChunk & { rawResponse?: any }>({
      transform(chunk, controller) {
        const newChoices = chunk.choices.map((choice) => {
          const message = choice.delta
          if (isToolCall == null) isToolCall = isToolCallAssistantMessage(message)
          if (isToolCall) {
            return {
              ...choice,
              finish_reason: 'tool_calls',
              delta: message,
            }
          }
          return choice
        })
        const newChunk = { ...chunk, choices: newChoices }
        controller.enqueue({ ...newChunk, rawResponse: chunk })
      },
    }),)

    return createAsyncIterable(streamWithRaw)
  }
}
