import {
  createAsyncIterable,
  TransformStream,
  toPolyfillReadable,
  intoStandardStream,
  isToolCallAssistantMessage,
} from '../../utils'
import type { MoonshotGenerateTextOutput, MoonshotStreamTextOutput, MoonshotInputData } from './type'
import type {
  ModelReq,
  BaseDoStreamOutputChunk,
  DoGenerateOutput,
  DoStreamOutput,
  SimpleChatModel,
  BaseChatModelInput,
} from '../../type'

function processInput(input: BaseChatModelInput): MoonshotInputData {
  const { messages, model, temperature, tools, top_p } = input

  return {
    ...input,
    messages,
    model,
    tools,
    top_p,
    temperature,
  }
}

export class MoonshotSimpleModel implements SimpleChatModel {
  public subUrl = 'moonshot/v1/chat/completions'
  constructor(private req: ModelReq, public baseUrl: string, subUrl?: string) {
    if (subUrl != null) {
      this.subUrl = subUrl
    }
  }

  private get url() {
    return `${this.baseUrl}/${this.subUrl}`
  }

  public async doGenerate(data: BaseChatModelInput): Promise<DoGenerateOutput> {
    const res = (await this.req({
      url: this.url,
      data: {
        ...processInput(data),
        stream: false,
      },
      stream: false,
    })) as MoonshotGenerateTextOutput
    return { ...res, rawResponse: res }
  }

  public async doStream(data: BaseChatModelInput): Promise<DoStreamOutput> {
    let isToolCall: null | boolean = null
    const _stream = await this.req({
      url: this.url,
      data: {
        ...processInput(data),
        stream: true,
      },
      stream: true,
    })
    const stream = toPolyfillReadable(_stream) as typeof _stream

    const arkStream = intoStandardStream<MoonshotStreamTextOutput>(stream)
    const streamWithRaw = arkStream.pipeThrough(new TransformStream<MoonshotStreamTextOutput, BaseDoStreamOutputChunk & { rawResponse?: any }>({
      transform(chunk, controller) {
        const newChoices = chunk.choices.map((choice) => {
          const message = choice.delta
          if (isToolCall == null) isToolCall = isToolCallAssistantMessage(message)
          if (isToolCall) {
            return {
              ...choice,
              finish_reason: 'tool_calls' as const,
              delta: message,
            }
          }
          return choice
        })
        const newChunk = { ...chunk, choices: newChoices }
        controller.enqueue({ ...newChunk, rawResponse: chunk })
      },
    }),)

    return createAsyncIterable(streamWithRaw)
  }
}
