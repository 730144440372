import { HunYuanInputData } from './type'
import { BaseChatModelInput } from '../../type'

/**
 * 这里的 process 只是对不符合的 input 做 warning。
 * 实际上还是会如实传递。
 * 防止未来模型参数更新，SDK 没跟上而导致用户输入无法生效。
 */
export function processInput(input: BaseChatModelInput): HunYuanInputData {
  const { messages, model, temperature, tool_choice, tools, top_p } = input

  const handleTools = () => {
    if (!tools) return undefined
    tools.forEach((tool) => {
      if (tool.type !== 'function') console.warn('`type` in tool is not \'function\'', tool)
    })
    return tools as HunYuanInputData['tools']
  }

  const handleMessages = () => {
    messages.forEach((message) => {
      if ('tool_calls' in message) {
        message.tool_calls
          .filter(tool_call => tool_call.type !== 'function')
          .forEach(tool_call => console.warn('`type` in tool_call is not \'function\'', tool_call, message))
      }
    })
    return messages as HunYuanInputData['messages']
  }

  return {
    ...input,
    messages: handleMessages(),
    model,
    tools: handleTools(),
    top_p,
    tool_choice,
    temperature,
  }
}

export function titleCaseToSnakeCase(obj: unknown) {
  if (typeof obj !== 'object' || obj == null) return obj // 如果不是对象或者是null，则直接返回

  if (Array.isArray(obj)) return obj.map(item => titleCaseToSnakeCase(item)) // 如果是数组，递归处理每个元素

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const snakeCaseKey = toSnakeCase(key)

    acc[snakeCaseKey] = typeof value === 'object' ? titleCaseToSnakeCase(value) : value
    return acc
  }, {})

  function toSnakeCase(s: string) {
    const ret = s.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
    return ret.charAt(0) === '_' ? ret.slice(1) : ret
  }
}
